import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import compact from 'lodash/compact';
import { UserData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';

import { ReducersProps } from '~/reducers';

import Button from '../../components/Button';
import { DataFieldProps, Form } from '../../packages/formidable';
import { borderRadius, spacing } from '../../styles/theme';

export const LoginEL = styled.div`
  background: #fff;
  padding: ${spacing[6]};
  margin: 0 auto;
  border-radius: ${borderRadius.s};
  border: 1px solid #e5e7eb;
`;

const Login: FC<{ href: string }> = ({ href }) => {
  const formName = 'login';
  const [message, setMessage] = useState<string>();
  const [isSubmissive, setIsSubmissive] = useState<boolean>(true);
  const [kind, setKind] = useState<'login' | 'reset' | 'link'>('login');
  const {
    cms: { userIsLogged, user },
  } = useSelector((globalState: ReducersProps<any>) => globalState);

  useEffect(() => {
    try {
      UserData.signInWithEmailLink(href);
    } catch (e: unknown) {
      toast.error((e as Error).message);
    }
  }, [href]);

  const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setKind('reset');
  };

  const handleLinkOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setKind('link');
    setMessage('');
  };

  const handleLoginOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setKind('login');
    setMessage('');
  };

  useEffect(() => {
    if (userIsLogged && user) {
      const espaceId =
        user.espaceIds && user.espaceIds.length > 0 && user.espaceIds[0];
      if (espaceId) {
        navigate(`/espaces/${espaceId}/dashboard`);
      } else {
        navigate(`/profile`);
      }
    }
  }, [userIsLogged, user?.id]);

  const handleFormSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password?: string;
  }): Promise<void> => {
    setIsSubmissive(false);
    // let valid = false;
    // try {
    //   valid = await UserData.signInWithAuthCode(email, code);
    // } catch (error) {
    //   throw new SubmissionError({
    //     _error: (error as Error).message,
    //   });
    // }
    //
    // if (!valid) {
    //   throw new SubmissionError({
    //     _error: "Le code n'est pas bon",
    //   });
    // }

    if ('login' === kind && !password) {
      throw new SubmissionError({
        _error: 'Le mot de passe est obligatoire',
      });
    }

    try {
      switch (kind) {
        case 'link': {
          await UserData.sendSignInLinkToEmail(email, href);
          setMessage(
            "S'il existe un compte avec cette adresse e-mail, un lien de connexion sera envoyé",
          );
          break;
        }

        case 'reset': {
          await UserData.sendPasswordResetEmail(email);
          setMessage(
            "S'il existe un compte avec cette adresse e-mail, un lien de réinitialisation sera envoyé",
          );
          break;
        }

        case 'login': {
          await UserData.signInWithEmailAndPassword(email, password as string);
          break;
        }

        default:
      }
    } catch (e) {
      throw new SubmissionError({
        _error: (e as Error).message,
      });
    }
    setIsSubmissive(true);
  };

  let submitLabel = 'Se connecter';

  switch (kind) {
    case 'link': {
      submitLabel = 'Envoyer le lien par e-mail';
      break;
    }

    case 'reset': {
      submitLabel = 'Envoyer un e-mail';
      break;
    }

    default:
  }

  return (
    <>
      <LoginEL className="flex flex-col justify-center space-y-3">
        <Form
          bodyProps={{
            className: 'flex flex-col space-y-3 mb-12',
          }}
          datas={compact([
            {
              componentType: 'input',
              fieldProps: {
                autoComplete: 'email',
                inputMode: 'email',
              },
              label: 'Adresse e-mail',
              name: 'email',
              required: true,
              type: 'email',
            } as DataFieldProps,
            'login' === kind &&
              ({
                componentType: 'input',
                fieldProps: {
                  autoComplete: 'current-password',
                  inputMode: 'text',
                },
                label: 'Mot de passe',
                name: 'password',
                required: true,
                type: 'password',
                wrapperProps: {
                  className: 'relative',
                },
              } as DataFieldProps),
            // {
            //   componentType: 'input',
            //   fieldProps: {
            //     autoComplete: 'one-time-code',
            //     inputMode: 'numeric',
            //     pattern: '[0-9]*',
            //   },
            //   label: 'Code authentification',
            //   name: 'code',
            //   required: true,
            //   type: 'text',
            // } as DataFieldProps,
          ])}
          footerProps={{
            className: 'flex flex-col space-y-3 justify-between',
          }}
          isSubmissive={isSubmissive}
          name={formName}
          onSubmit={handleFormSubmit}
          submitProps={{
            className: 'w-full',
            label: submitLabel,
          }}
        >
          {message && (
            <span className="text-center text-info-800">{message}</span>
          )}
        </Form>
      </LoginEL>
      <div className="mt-1.5 flex justify-between">
        {isSubmissive && 'login' === kind && (
          <Button
            className="text-center"
            onClick={handleLinkOnClick}
            variant="link"
          >
            Se connecter sans mot de passe
          </Button>
        )}
        {['reset', 'link'].includes(kind) && (
          <Button
            className="text-center"
            onClick={handleLoginOnClick}
            variant="link"
          >
            Revenir à la page de connexion
          </Button>
        )}
        {'login' === kind && (
          <Button onClick={handleResetOnClick} variant="link">
            Mot de passe oublié ?
          </Button>
        )}
      </div>
    </>
  );
};

export default Login;
